export class StarName {

    stars = [...new Set([
        "Achernar",
        "Acrux",
        "Adhara",
        "Al Dhanab",
        "Aldebaran",
        "Algieba",
        "Algol",
        "Alhena",
        "Alioth",
        "Alkaid",
        "Alnair",
        "Alnilam",
        "Alnitak",
        "Alphard",
        "Altair",
        "Antares",
        "Arcturus",
        "Atria",
        "Avior",
        "Bellatrix",
        "Betelgeuse",
        "Canopus",
        "Capella",
        "Castor",
        "Deneb",
        "Dubhe",
        "Elnath",
        "Fomalhaut",
        "Gacrux",
        "Hadar",
        "Hama",

        "Koo She",
        "Kochab",
        "Ras Alhague",
        "Algol",
        "Alamak",
        "Denebola",
        "Naos",
        "Tureis",
        "Gemma",
        "Sadr",
        "Schedar",
        "Eltanin",
        "Caph",
        "Dschubba",
        "Merak",
        "Izar",

        "Menkalinan",
        "Miaplacidus",
        "Mimosa",
        "Mintaka",
        "Mirach",
        "Mirfak",
        "Mirzam",
        "Mizar",
        "Peacock",
        "Polaris",
        "Pollux",
        "Procyon",
        "Regor",
        "Regulus",
        "Rigel",
        "Saiph",
        "Sargas",
        "Shaula",
        "Sirius",
        "Spica",
        "Vega",
        "Wezen",

        "Andromeda",
        "Antlia",
        "Apus",
        "Aquarius",
        "Aquila",
        "Ara",
        "Aries",
        "Auriga",
        "Bootes",
        "Caelum",
        "Camelopardalis",
        "Cancer",
        "Canis",
        "Capricornus",
        "Carina",
        "Cassiopeia",
        "Centaurus",
        "Cepheus",
        "Cetus",
        "Chamaeleon",
        "Circinus",
        "Columba",
        "Coma Berenices",
        "Corona",
        "Corvus",
        "Crater",
        "Crux",
        "Cygnus",
        "Delphinus",
        "Dorado",
        "Draco",
        "Equuleus",
        "Eridanus",
        "Fornax",
        "Gemini",
        "Grus",
        "Hercules",
        "Horologium",
        "Hydra",
        "Hydrus",
        "Indus",
        "Lacerta",
        "Leo",
        "Lepus",
        "Libra",
        "Lupus",
        "Lynx",
        "Lyra",
        "Mensa",
        "Microscopium",
        "Monoceros",
        "Musca",
        "Norma",
        "Octans",
        "Ophiuchus",
        "Orion",
        "Pavo",
        "Pegasus",
        "Perseus",
        "Phoenix",
        "Pictor",
        "Pisces",
        "Puppis",
        "Pyxis",
        "Reticulum",
        "Sagitta",
        "Sagittarius",
        "Scorpius",
        "Sculptor",
        "Scutum",
        "Serpens",
        "Sextans",
        "Taurus",
        "Telescopium",
        "Triangulum",
        "Tucana",
        "Ursa",
        "Vela",
        "Virgo",
        "Volans",
        "Vulpecula",

        "Simba",
        "Timone",
        "Pumba",
        "Scar",
        "Nala",
        "Jafar",
        "Iago",
        "Jasmine",
        "Wheaton",
        "Sol",
        "Terok Nor",
        "Abydos",
        "Chulak",

    ])];

    pick() {
        return this.stars.splice(Math.random()*this.stars.length, 1)[0];
    }
}
