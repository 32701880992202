export async function handleResponse(response) {

    if (response.ok) {
        let json = await response.json();
        return json;
    }
    if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
        const error = await response.text();
        throw new Error(error);
    }

    if (response.status === 401) {
        const json = await response.json();
        if (json.error === 'Token Expired') {
            throw new Error('Token Expired');
        }
    }

    if (response.status === 403) {
        throw new Error('Unauthorized');
    }

    throw new Error('Network response was not ok.');
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
    // eslint-disable-next-line no-console
    console.error('API call failed. ' + error);
    throw error;
}
