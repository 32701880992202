
const SpeciesDesignPage = () => {
    return (
        <>
            <h1>SpeciesDesignPage</h1>
        </>
    );
};

export default SpeciesDesignPage;
